body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(12,12,20)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

/* prevent blue border on search input when focussed */
input:focus {
  outline-width: 0;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px); /* Adjust the distance to move up */
  }
}

/* global */

input[type="file"] {
  display: none;
}

.page-title {
  position: relative;
  font-size: 22px;
  font-weight: 200;
  margin: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
  color: white;
}

.dropdown-msg-container {
  z-index: 999;
  position: absolute;
  top: 10px;
  left: 100px;
  background-color: blue;
  color: white;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 10px;
  border-radius: 5px;
  box-shadow: 4px 3px 15px rgba(0, 0, 0, .6);
  cursor: pointer;
}

.dropdown-msg-container p {
  margin: 0;
  padding: 0;
}

.dropdown-msg-container:hover {
  background-color: rgb(0, 0, 221);
}

.big-button {
  position: relative;
  background-color: rgb(54, 117, 243);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  display: inline-block;
  transition: background-color 0.2s;
  width: 80%;
  border: none;
  bottom: 0px;
  max-width: 300px;
}

/* Modal */

.modal-wrapper-large {
  position: fixed; /* Stay in place */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99; /* Sit on top */
  /* overflow: auto; Enable scroll if needed */
  background-color: rgba(12, 12, 20, .96);
  max-width: 450px;
  width: 100%;
  height: 640px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .8);
  /* no scroll */
  overflow: visible;
}

.modal-wrapper-small {
  position: fixed; /* Stay in place */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99; /* Sit on top */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(12, 12, 20, .98);
  border-radius: 10px;
  max-width: 450px;
  width: 88%;
  min-height: 40%;
  max-height: 60%;
  padding: 0 18px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .7);
}

.modal-wrapper-custom {
  position: fixed; /* Stay in place */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999; /* Sit on top */
  /* overflow: auto; Enable scroll if needed */
  background-color: white;
  max-width: 900px;
  width: 100%;
  max-height: 640px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .8);
  /* no scroll */
  overflow: auto;
}

.modal-wrapper-wide {
  position: fixed; /* Stay in place */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99; /* Sit on top */
  /* overflow: auto; Enable scroll if needed */
  background-color: rgba(12, 12, 20, .94);
  min-height: 330px;
  width: 95%;
  max-width: 700px;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .8);
  /* border: 1px solid rgba(255, 255, 255, 0.453); */
  /* no scroll */
  overflow: visible;
  padding-bottom: 50px;
}

.modal-wrapper-login {
  position: fixed; /* Stay in place */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99; /* Sit on top */
  /* overflow: auto; Enable scroll if needed */
  background: linear-gradient(rgba(255, 255, 255, 0.96), rgba(255, 255, 255, 0.96)), url('/src/images/topography.jpg'); /* Background image */
  min-height: 350px;
  width: 90%;
  max-width: 700px;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .8);
  /* border: 1px solid rgba(255, 255, 255, 0.453); */
  /* no scroll */
  overflow: visible;
  padding-bottom: 50px;
}

.modal-wrapper p {
  position: relative;
  color: white;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-content {
  position: relative;
  top: 15px;
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.fishing-map-species-search {
  position: absolute;
  top: 100px;
  left: 90px;
  color: white;
  margin: 0;
  padding: 0;
}

.fishing-map-species-search ul {
  margin: 0;
  padding: 0;
}

.fishing-map-species-search li {
  list-style: none;
  margin: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  padding: 2px 5px;
  /* when not selected */
  background-color: rgba(83, 83, 120, 0.6);
  text-align: center;
  border-radius: 5px;
  border: .5px solid rgba(219, 219, 219, 0.815);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border 0.2s;
  min-height: 28px;
}

.fishing-map-species-search li.selected {
  background-color: rgba(12, 12, 20, 1);
}


.fishing-course-modal-content {
  width: 100vw;
  height: 100vh;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  font-family: 'Arial', sans-serif;
  text-align: left;
  margin-left: 70px;
  z-index: 999999999;
}

.fishing-location-info {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  color: white;
}

.fishing-location-info h3 {
  margin-top: 0;
  font-size: 1.4rem;
}

.fishing-location-info p {
  margin: 8px 0;
  font-size: 1rem;
}

.fishing-location-info strong {
  color: #f4c300; /* Gold color for emphasis */
}

.fishing-location-info ul {
  margin: 5px 0 10px;
  padding-left: 20px;
}

.fishing-location-info ul li {
  font-size: 0.95rem;
}

.sidebar-container {
  position: absolute;
  top: 0;
  width: 100%;
  color: white;
  padding: 0px;
  margin: 0px;
  overflow: hidden; /* Prevent the sidebar itself from scrolling */
  display: flex;
  flex-direction: column;
}

.location-content {
  position: relative;
  height: 100%;
}

.location-table {
  width: 100%;
  border-collapse: collapse;
  background-color: rgba(12,12,20,.85);
}

.location-table thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.location-table tbody {
  display: block;
  max-height: 100vh; /* Adjust this based on your layout */
  overflow-y: auto;
  width: 100%;
}

.location-table tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.location-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #66666665;
}

/* Minimized state */
.sidebar-container.minimized {
  height: 35px; /* Shrinks the panel */
  overflow: hidden;
  width: 250px;
  backdrop-filter: blur(10px);
}

/* Hide table when minimized */
.sidebar-container.minimized .location-content {
  display: none;
}

.marker-circle {
  /* background-color: rgb(218, 38, 237); */
  position: relative;
  top: 15px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(199, 199, 199, 0.292);
  transition: transform 0.3s ease-in-out;
}

.marker-circle.hovered {
  transform: scale(2); /* Slightly enlarge on hover */
  background-color: rgb(253, 114, 0); /* Lighter highlight */
}  

/* Minimize button */
.toggle-minimize {
  position: absolute;
  left: -30px; /* Moves button to the left of the sidebar */
  top: 50%; /* Centers it vertically */
  transform: translateY(-50%); /* Ensures proper vertical centering */
  background: #222;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-sidebar {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 5px;
}

.location-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.location-table th, .location-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #66666665;
}

.location-table tr:hover {
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.toggle-sidebar {
  position: absolute;
  top: 15px;
  left: 15px;
  background: rgba(15, 15, 15, 0.9);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 9999999999;
}

.fishing-course-modal-content h1 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 20px;
}

.fishing-course-modal-content h2 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
  border-bottom: 2px solid #02468242;
  padding: 10px;

}

.fishing-course-modal-content h3 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  color: #0061c3;
}

.fishing-course-modal-content p {
  font-size: 14px;
  line-height: 1.8;
  margin-bottom: 15px;
  color: #333;
  padding: 10px;
}

.fishing-locations-list {
  height: 600px;
  overflow-y: auto; /* Allow scrolling */
  padding: 0px 20px;
  color: white;
}

.fishing-location {
  padding: 2px 15px;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, transform 0.2s ease;
}

.fishing-location:hover {
  background: rgba(255, 165, 0, 0.8); /* Highlight on hover */
  transform: scale(1.02); /* Slight zoom effect */
  color: white;
}

/* Scrollbar Styling */
.fishing-locations-list::-webkit-scrollbar {
  width: 6px;
}

.fishing-locations-list::-webkit-scrollbar-thumb {
  background: rgba(255, 165, 0, 0.8); /* Orange scrollbar */
  border-radius: 5px;
}

.fishing-locations-list::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

/* Lists */
.fishing-course-modal-content ul {
  margin-left: 25px;
  padding-left: 15px;
}

.fishing-course-modal-content li {
  font-size: 16px;
  margin-bottom: 5px;
}

/* Main container for layout */
.home-page-container, .home-page-container-reverse {
  background: linear-gradient(rgba(16,15,27, .96), rgba(35, 34, 38, 0.96)), url('/src/images/topography.jpg'); /* Background image */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Push text and image to opposite sides */
  width: 100%; /* Ensure full width without overflow */
  margin: 0 auto; /* Center the content */
  padding: 20% 5%;
  box-sizing: border-box; /* Ensures padding doesn't cause overflow */
}

/* Logo positioning (Top Left Corner) */
.home-page-logo-container {
  position: absolute;
  top: 0;
  left: 0;
}

.home-page-logo {
  width: 40%; /* Adjust size as needed */
  max-width: 750px;
  height: auto;
}

/* Navbar Container */
.home-page-navbar {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  z-index: 1000;
}

.sign-up-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 300px;
  margin: auto;
}

.create-password-wrapper {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(rgba(255, 255, 255, 0.96), rgba(255, 255, 255, 0.96)),
              url('/src/images/topography.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-password-form {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.create-password-form input {
  padding: 12px;
  font-size: 16px;
}

.sign-up-form input,
.sign-up-form button {
  padding: 12px;
  font-size: 16px;
}

.sign-up-modal {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;     /* vertical center */
  align-items: center;         /* horizontal center */
  gap: 30px;                   /* space between buttons */
  height: 100%;
  padding: 40px;               /* some breathing room inside */
  box-sizing: border-box;
}

.sign-up-header {
  color: black;
  text-align: center;
}

.sign-up-button {
  border-radius: 20px;
  border: 1px solid black;
  width: 100%;                 /* make buttons fill available width */
  max-width: 300px;            /* prevent them from getting too wide */
  padding: 14px 20px;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-p {
  color: grey;
  text-align: center;
}

.login-p:hover {
  cursor: pointer;
}

.google-sign-up-button {
  padding: 10px 20px 10px 20px;
  background-color: #3d3d3d;
  color: white;
}

.google-sign-up-button:hover {
  background-color: rgb(40, 40, 40);
  cursor: pointer;
}

.email-sign-up-button {
  background-color: transparent;
  color: black;
}

.sign-up-button:hover {
  background-color: rgb(38, 38, 38);
  cursor: pointer;
  color: white;
}

/* Align Buttons in Top Right */
.auth-buttons {
  position: absolute;
  top: 0;
  right: 5%;
  display: flex;
  gap: 15px;
}

/* Button Styling */
.login-btn, .orange-btn {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

/* Login Button */
.login-btn {
  background: transparent;
  color: white;
  border: 1px solid white;
}

.login-btn:hover {
  background: white;
  color: black;
}

/* Sign Up Button */
.orange-btn {
  background: #ff9800;
  color: white;
  font-weight: bold;
}

.orange-btn:hover {
  background: #e68900;
}

/* Text Section (Left Side) */
.home-page-text {
  flex: 1;  /* Takes up remaining space */
  max-width: 45%; /* Prevents text from stretching too wide */
  font-size: 22px;
  color: white;  /* Adjust for visibility */
  padding: 50px;
}

/* Carousel styling */
.carousel {
  flex: 1;
  max-width: 50%;
}

/* Each slide container */
.slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-prev:before, 
.slick-next:before {
    color: black !important;
}

/* Individual images */
.carousel-img {
  width: 75%; /* Prevents overflow */
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

/* Screenshot Image (Right Side) */
.screenshot-img-wind .screenshot-img-wave {
  flex: 1;  /* Takes up remaining space */
  max-width: 50%; /* Ensures the image never exceeds 50% of the container */
  height: auto;  /* Maintain aspect ratio */
  display: block;
  object-fit: contain; /* Ensures the whole image fits inside */
  box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.463);
  border-radius: 10px;
}

.slick-list {
  padding: 0 50px; /* Add padding so images aren't too close */
  height: auto;
}

.slick-track {
  padding: 0px;
}

.carousel-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tile-grid {
  background-color: white;
  padding: 30px;
  background: linear-gradient(rgba(203, 203, 203, 0.96), rgba(228, 227, 230, 0.96)), url('/src/images/topography.jpg'); /* Background image */

}

.tile-grid h1 {
  padding: 0;
  margin: 0;
  color: rgb(20, 22, 70);
  text-align: center;
}

/* Restrict height for the multi-image carousel */
.multi-carousel-section {
  background-color: rgb(196, 196, 197);
  padding: 40px 0; /* Ensure the section only takes required space */
}

/* Ensure slick-track doesn't stretch */
.multi-carousel .slick-track {
  display: flex !important;
  align-items: center; /* Prevent stretching */
}

/* Fix excessive height issue */
.multi-carousel .slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: auto !important; /* Prevent stretching */
  padding: 10px;
}

/* Restrict image height */
.multi-carousel .carousel-img {
  width: 100%;
  max-height: 400px; /* Adjust if needed */
  object-fit: contain; /* Ensures images scale properly */
}

/* Adjust slick list to prevent extra spacing */
.multi-carousel .slick-list {
  height: auto !important; /* Fix excessive height */
  padding: 0 10px;
}

.carousel-slide {
  padding: 10px;
}

.carousel-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.carousel-img:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.home-page-image {
  width: 50%;
  height: auto;
  max-width: 350px;
  min-width: 250px;
}

.home-page-image-large {
  width: 50%;
  height: auto;
  max-width: 500px;
  min-width: 250px;
}

.home-page-image-small {
  width: 30%;
  min-width: 180px;
  height: auto;
}

/* Wrapper to Align Text & Carousel */
.fishing-carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 40px 20px;
  box-sizing: border-box;
  background: linear-gradient(90deg, rgba(16,15,27,1) 20%, rgb(43, 43, 65) 60%, rgb(34, 32, 50) 100%);
}

/* Text Section (Left Side) */
.fishing-carousel-text {
  flex: 1;
  max-width: 75%;
  font-size: 18px;
  color: white;
}

/* Carousel Container (Right Side) */
.fishing-carousel-container {
  flex: 1;
  max-width: 30%;
}

/* Make Sure Images Scale Properly */
.fishing-carousel-img {
  width: 80%;
  height: auto;
  border-radius: 10px;
}

/* Glass Effect Grid */
.glass-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1100px;
  margin: auto;
  padding: 50px;
}

/* Glassmorphism Card */
.glass-card {
  background: rgba(50, 50, 75, 0.77);
  border-radius: 20px;
  padding: 20px;
  text-align: left;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Title & Text */
.glass-card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.glass-card-text {
  font-size: 14px;
  opacity: 0.8;
}

/* Icon Styling */
.glass-card-icon {
  font-size: 30px;
  margin-bottom: 10px;
  opacity: 0.8;
}


/* Responsive Design: Adjust Layout for Small Screens */
@media (max-width: 768px) {
  .home-page-container {
      flex-direction: column;
      /* text-align: center; */
  }

  .home-page-text, .screenshot-img-wind {
      max-width: 90%; /* Allow full width within limits */
  }

  .screenshot-img-wind {
      margin-top: 20px;
      width: 100%; /* Allow full width within container */
  }
}

/* Reset password */

.reset-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.reset-form-container {
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.reset-heading {
  margin-bottom: 0.5rem;
}

.reset-subtext {
  margin-bottom: 1.5rem;
  color: #555;
}

.reset-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reset-input {
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.reset-button {
  padding: 0.75rem;
  background-color: #ff7f0e;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 10px;
}

.reset-button:hover {
  background-color: #ff9a3c;
  cursor: pointer;
}

/* Tides */

.tide-page {
  display: flex;
  width: 100%;
}

/* add dynamic styling for desktop vs mobile */
.tide-line-chart {
  width: 370px;
  height: 600px;
  margin: 0 auto;
}

.loading-spinner-wrapper {
  position: absolute;
  display: flex; /* Centers child vertically and horizontally */
  justify-content: center;
  align-items: center;
  height: 640px; /* or whatever height your modal needs */
  width: 100%;
  z-index: 999; /* Ensure it appears above other content */
}

.loading-spinner {
  width: 36px; /* Set a specific width for the spinner */
  height: 36px;
}

/* home page subscription plan */

.subscription-container {
  text-align: center;
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;
}

.subscription-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #004b76;
}

.subscription-subtitle {
  color: #555;
  margin-bottom: 30px;
}

.plans-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.stripe-button-wrapper {
  display: flex;
  justify-content: center;
}

.stripe-submit-btn {
  padding: 10px 20px;
  margin: 50px;
  background-color: #0077c2;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 50%;
  text-align: center;
}

.plan-card {
  width: 28%;
  min-width: 200px;
  background-color: white;
  padding: 25px;
  border-radius: 15px;
  border: 2px solid #f1f9ff; /* Neutral border to maintain size */
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  font-size: .85rem;
}

.plan-card.selected {
  border-color: #0077c2;
  background-color: #e6f4ff;
}

.plan-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan-header h3 {
  margin: 0;
  font-size: 22px;
  color: #004b76;
}

.badge {
  background-color: #ffc107;
  color: #000;
  font-weight: bold;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 5px;
}

.plan-price h2 {
  font-size: 26px;
  margin: 15px 0 5px;
  color: #0077c2;
}

.plan-price span {
  font-size: 14px;
  color: #555;
}

.select-btn {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #0077c2;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.select-btn:hover {
  background-color: #005e9c;
}


/* Fishing Map */

.fishing-map-page {
  display: flex;
  width: 100%;
}

.hovered-area {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(12,12,20,1);
  color: white;
  font-size: 1em;
  /* width: 200px; */
  text-align: center;
  font-weight: 600;
  padding: 10px;
  max-width: 300px;
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.species-search-input {
  position: relative;
  background-color: transparent;
  border: none;
  border-bottom: .5px solid rgba(255, 255, 255, 0.262);
  display: flex;
  margin: 0 auto;
  text-align: center;
  bottom: 10px;
  height: 35px;
  width: 200px;
  color: white;
  font-size: 18px;
  font-weight: 100;
  letter-spacing: .5px;
}

.checkbox-container {
  position: absolute;
  top: 120px;
  right: 15px;
  width: 50px;
  z-index: 1;
}

.toggle-checkbox {
  display: none;
}

.map-checkbox-item {
  margin: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  padding: 3px;
  background-color: rgba(83, 83, 120, 0.6);
  text-align: center;
  border-radius: 5px;
  border: .5px solid rgba(219, 219, 219, 0.815);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border 0.2s;
  min-height: 28px;
}

.selected-map-checkbox {
  background-color: rgba(12, 12, 20, 1);
  border: .5px solid white;
}

.map-checkbox-item:hover {
  cursor: pointer;
  background-color: rgba(12, 12, 20, 1);
}

/* Fishing Map Modal */

.fishing-regs-tile-container {
  width: 100%;
  max-height: 450px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  position: relative;
  row-gap: 0px;
  overflow: scroll; /* or auto, depending on your preference */
}

.fishing-regs-tile-container-header {
}

.fishing-regs-tile-container-limits {
  width: 100%;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  position: relative;
  gap: 10px;
}

.fishing-regs-tile-container-small {
  width: 100%;
  /* overflow-y: auto; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  place-items: center;
  position: relative;
  gap: 10px;
  margin-bottom: 80px;
  place-items: center;
}

.fishing-regs-tile {
  text-align: center;
  border-radius: 10px;
  margin-top: 15px;
  height: 120px;
  width: 160px;
  color: white;
  /* border: 1px solid rgba(255, 255, 255, 0.387); */
  display: flex;
  align-items: center; /* Align vertically */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
  margin-bottom: 10px;
  background: linear-gradient(90deg, rgba(16,15,27,1) 20%, rgba(20,20,30,1) 60%, rgba(16,15,27,1) 100%);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.1);
}

.fishing-regs-tile:hover {
  cursor: pointer;
  background-color: rgb(26, 26, 27);
  /* box-shadow: 0 0 8px rgba(255, 255, 255, 0.5); */
}

.tile-text {
  font-size: 1.2rem;
  overflow: auto;
  max-width: 90%;
  margin: 0 auto;
  }

.fishing-reg-card {
  width: 100%;
}

.fishing-reg-card-status {
  width: 80%;
  min-height: 50px;
  margin: 5px auto;
  margin-top: 5px;
  font-size: 26px;
  font-weight: 500;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid rgba(255, 255, 255, 0.387); */
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.fishing-reg-card-map-link {
  width: 80%;
  min-height: 40px;
  margin: 0px auto;
  margin-top: 5px;
  font-size: 24px;
  font-weight: 300;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fishing-reg-card-map-link:hover {
  cursor: pointer;
}

.fishing-reg-card-map-link a {
  color: orange;
}

.fishing-reg-card-details {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-around;
  color: white;
}

.fishing-reg-card-details div {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 155px;
  height: 120px;
  margin: 10px;
  overflow: scroll;
  justify-self: center;
  border-radius: 10px;
  /* border: 1px solid rgba(255, 255, 255, 0.387); */
}

.fishing-reg-card-details h5 {
  font-size: 17px;
  font-weight: 500;
  margin: 10px;
}

.fishing-reg-card-details h4 {
  font-size: 28px;
  font-weight: 400;
  margin: 10px;
  text-align: center;
}

.modal-return-to-btn {
  position: absolute;
  top: -25px;
  left: 5px;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  color: white;
}

.fishing-area-text {
  position: absolute;
  top: -29px;
  left: 70%;
  transform: translateX(-50%);
  font-weight: 400;
  cursor: pointer;
  color: orange;
  margin: 0;
  padding: 0;

}

.modal-area-text {
  position: absolute;
  top: -29px;
  right: 15px;
  padding: 5px 10px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  color: orange;
  margin: 0;
  padding: 0;
}

/* simple close btn */

.simple-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
  color: orange;
  z-index: 9999999;
}

.simple-close-btn:hover {
  color: rgb(209, 136, 2);
}

/* Weather Map */

.slide-btn {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  padding: 10px;
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  background: orange;
  color: white; /* White text color for contrast */
  font-size: 14px; /* Adjust font size */
  font-weight: 400; /* Bold text for emphasis */
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
  transition: background 0.3s, transform 0.2s; /* Smooth transition effects */
  z-index: 10; /* Ensure it stays on top of other elements */
}

.slide-btn:hover {
  background: rgb(223, 145, 0); /* Gradient background */
}


.slide-pane__close svg {
  transform: rotate(180deg);
}

.slide-pane__close svg:hover {
  cursor: pointer;
  color: orange;
}

.weather-slider {
  z-index: 999999999;
}

.slide-pane__header {
  background-color: rgb(12,12,20)!important;
  color: white;
}

.slide-pane__content {
  border-top: .5px solid rgba(234, 228, 228, 0.441);
  background-color: rgb(12, 12, 20)!important;
  color: whitesmoke;
}

.slide-pane__content p {
  margin: 10px 2px;
  padding: 0px;
}

.slide-pane__content div {
  background: rgb(20,20,30);
  background: linear-gradient(90deg, rgba(16,15,27,1) 20%, rgba(20,20,30,1) 60%, rgba(16,15,27,1) 100%);  margin: 15px;
  /* padding: 5px; */
  border-radius: 10px;
  margin: 20px 5px;
}

.forecast-summary {
  position: absolute;
  top: 120px;
  left: 120px;
  z-index: 9999999;
  width: 650px;
  color: white;
  margin: 0px;
}

.wind-direction-weather-summary {
  position: absolute;
  top: 10px;
  right: 0px;
  color: white;
  font-size: 20px;
  font-weight: 400;
}

.weather-map-page {
  display: flex;
  width: 100%;
}

.weather-photo-upload {
  position: fixed;
  left: 10px;
  font-size: 16px;
  top: 10px;
  z-index: 999;
  color: white;
  padding: 3px 8px;
  box-shadow: 0 0 8px rgb(40, 39, 39, .5);
  border-radius: 5px;
  background: rgb(20,20,30);
  border: .5px solid rgba(255, 255, 255, 0.739);
  background: linear-gradient(90deg, rgba(16,15,27,1) 20%, rgb(46, 46, 67) 60%, rgb(46, 43, 68) 100%);
}

.webcam-window {
  position: absolute;
  z-index: 9999;
  width: 650px;
  max-width: 96%;
  height: 585px;
  max-height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
}

.webcam-iframe {
  position: absolute;
  bottom: 155px;
  left: 7px;
}

.webcam-border {
  position: absolute;
  top: 0;
  left: 7.5px;
  width: 95.8%;
  height: 65%;
  border: 10px solid rgb(20, 20, 30);
}

.webcam-window2 {
  position: absolute;
  z-index: 9999;
  width: 650px;
  max-width: 96%;
  height: 585px;
  max-height: 65%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: scroll;
}

.webcam-iframe2 {
  position: absolute;
  bottom: 165px;
}

.webcam-window4 {
  position: absolute;
  z-index: 9999;
  width: 650px;
  max-width: 96%;
  height: 585px;
  max-height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
}

.webcam-iframe4 {
  position: absolute;
  bottom: -10px;
  left: 70px;
  width: 100%;
  border: none;
}

.webcam-img {
  position: absolute;
  width: 650px;
  max-width: 100%;
  height: 400px;
}

.webcam-img2 {
  position: absolute;
  width: 650px;
  max-width: 100%;
  height: 400px;
}

.webcam-window5 {
  position: absolute;
  z-index: 9999;
  width: 650px;
  max-width: 96%;
  height: 450px;
  max-height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.webcam-iframe5 {
  position: absolute;
  bottom: 88px;
}

.webcam-window6 {
  position: absolute;
  z-index: 9999;
  width: 650px;
  max-width: 96%;
  height: 610px;
  max-height: 64%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
}

.webcam-iframe6 {
  position: absolute;
  bottom: 155px;
  left: 8px;
  width: 100%;
  border: none;
}

.photo-upload-modal-header {
  color: white;
  text-align: center;
}

.photo-upload-modal-header h2 {
  margin: 5px;
  padding: 0;
}

.photo-upload-modal-body {
  color: white;
  text-align: center;
}

.photo-upload-modal {
  color: white;
}

.photo-upload-modal-btns {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 20px;
}

.weather-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.weather-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
}

.wind-color-box {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.755);
}

.wave-color-box {
  display: inline-block;
  width: 12px;
  height: 12px;
  padding: 1.5px 4px;
  margin-right: 5px;
  border-radius: 45%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.355);
}

/* .wave-height-label {
  text-shadow: 1px 1px 1px rgba(38, 38, 38, 0.732);  
  background-color: rgb(156, 1, 156);
  padding: 3px;
  border-radius: 25px;
} */

.info-window-container {
  width: fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
}

.map-info-window-data {
  margin-bottom: 10px;
}

.map-info-window-data p {
  font-weight: 400;
  font-size: 14px;
  padding: 0;
  margin: 3px;
}

.weather-map-settings-btn {
  position: absolute;
  top: 300px;
  right: 15px;
  width: 35px;
  height: 28px;
  z-index: 1;
  margin: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  padding: 3px;
  background-color: rgba(12,12,20,1);
  text-align: center;
  border-radius: 5px;
  border: .5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border 0.2s;
}

.weather-map-units-dropdown {
  position: absolute;
  top: 345px;
  right: 18px;
  width: 200px;
  height: 75px;
  background-color: rgba(12, 12, 20, .6);
  color: white;
  z-index: 1;
  border-radius: 5px;
}

.weather-map-units-container {
  margin: 5px
}

.weather-map-units-container h4 {
  margin: 0px; 
}

.weather-map-units-container input {
  margin: 5px;
}

.units-hide-arrow {
  position: absolute;
  top: 5px;
  right: 5px;
}

.units-hide-arrow:hover {
  cursor: pointer;
}

.slider-container {
  position: absolute;
  bottom: 23px;
  left: 80px;
  width: 85%;
  min-height: 30px;
  background-color: rgba(12, 12, 20, .6);
  border-radius: 10px;
  padding: 6px;
  display: flex;
  align-items: center;
}

.play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.slider-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider {
  position: relative;
  top: 7px;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  background: #ffffffd0;
  outline: none;
  opacity: .9;
  transition: opacity .15s ease-in-out;
  border-radius: 15px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(255, 255, 255);
  border: 2px solid #007bff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #007bff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.slider-labels {
  position: relative;
  top: 8px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-size: 12px;
  color: white;
  margin: 0;
  padding: 0;
}

.current-time-display {
  position: absolute;
  bottom: 85px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  background: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  font-weight: 400;
  border-radius: 10px;
  padding: 10px;
}

.forecast-hour {
  position: absolute;
  left: 80px;
  bottom: 70px;
  width: 65px;
  height: 25px;
  background-color: rgba(12, 12, 20, .6);
  border-radius: 5px;
  z-index: 99;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1px 2px;
}

.forecast-hour, .forecast-selector p {
  font-size: 12px;
}

.forecast-selector {
  position: absolute;
  left: 150px;
  bottom: 70px;
  width: 65px;
  height: 25px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  z-index: 99;
  color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1px 2px;
  cursor: pointer;
}

.wind-speed-window {
  display: flex; /* Enable Flexbox */
  align-items: center; /* Center vertically within the container */
  gap: 4px; /* Add spacing between elements */
  position: absolute;
  width: 90px;
  top: 0px; /* Adjust as needed */
  left: 60px; /* Adjust as needed */
  background-color: rgba(12, 12, 20, .6);
  padding: 6px 8px; /* Padding for visual comfort */
  border-radius: 5px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-size: 14px; /* Adjust font size as needed */
  font-weight: 400;
  color: white; /* Dark text color for readability */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.355);
  display: flex;
  justify-content: center;
}

.wind-speed-window p {
  margin: 0;
  padding: 0px;
}

/* Wind wave modal */

.wind-wave-toggle-btn {
  position: absolute;
  bottom: -30px;
  right: 10px;
  background-color: #007bff;
  border: .5px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  color: white;
  padding: 3px 5px;
}

.wind-wave-summary {
  position: absolute;
  bottom: -30px;
  right: 170px;
  background-color: #007bff;
  border: .5px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  color: white;
  padding: 3px 5px;
  transition: background 0.3s;
}


.wind-wave-summary:hover {
  background-color: #0056b3;
}

.loading-spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Ensure full width */
  height: 100%; /* Ensure full height */
}

.loading-spinner {
  width: 18px;
  height: 18px;
  border: 3px solid white;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


.wind-wave-ai-summary {
  position: relative;
  color: white;
  padding: 25px;
  font-size: 14px;
  background-color: rgba(12, 12, 20, 0.8);
  border-radius: 5px;
  max-height: 150px;
  overflow-y: auto;
}

.wind-wave-ai-summary-close {
  position: absolute;
  top: 5px;
  right: 10px;
  color: white;
  cursor: pointer;
  font-size: 18px;
}

/* Area Status Map */

.area-status-page {
  display: flex;
  width: 100%;
}

.species-area-search-header {
  color: white;
  text-align: center;
  margin: 25px auto;
  padding: 0;
  width: 80%;
}

.searched-info-container {
  position: absolute;
  top: 20px;
  right: 15px;
  width: 220px;
  height: 110px;
  background-color: rgba(29,29,29,.65);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9;
  color: white;
}

.searched-info-container h4 {
  margin: 3px;
  text-align: center;
}

.searched-info-container p {
  position: relative;
  margin: 3px;
  left: 30px;
}

.map-legend-square {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 5px;
  z-index: 9999;
  left: 10px;
  border: .5px solid rgba(255, 255, 255, 0.701);
}

.blue-square {
  background-color: rgba(19, 88, 235, 0.881);
}

.red-square {
  background-color: rgba(241, 80, 80, 0.877);
}

.orange-square {
  background-color: rgba(234, 126, 11, 0.877);
}

.area-status-search-again {
  position: absolute;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.area-status-search-again:hover {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.392);
}

.area-status-no-results {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 300px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.901);
  z-index: 9999;
  text-align: center;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.17);
  font-size: 20px;
}

/* Weather Table */

.weather-page {
  position: relative;
  display: flex;
  width: 100%;
}

.weather-wind-container {
  background: rgb(12,12,20);
  margin: 2%;
}

.weather-page h3 {
  color: white;
  font-weight: 400;
  margin: 1em 0 -5px 70px;
  padding: 0;
}

.weather-table-container {
  position: relative;
  width: 100vw;
  overflow: auto;
  margin-bottom: 50px;
}

.weather-table-tile-container {
  width: 100%;
  max-height: 30%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Adjusted */
  gap: 10px; /* Added gap between grid items */
  justify-content: center; /* Center grid items horizontally */
  text-align: center;
  align-items: center;
  color: white;
  background: rgb(12,12,20);
}

.weather-table-tile {
  position: relative;
  border-radius: 5px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /* border: .5px solid rgba(255, 255, 255, 0.408); */
  height: 75px;
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  margin: 5px 10px;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  background: rgb(20,20,30);
  background: linear-gradient(90deg, rgba(16,15,27,1) 20%, rgba(20,20,30,1) 60%, rgba(16,15,27,1) 100%);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.1);
}

.weather-table-tile:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.227);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.weather-table-tile-text {
  font-size: 20px;
  font-weight: 400;
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

.weather-table-tile-value {
  margin: 0;
  padding: 0;
  color: rgb(254, 208, 121);
}

.weather-table-active-dot {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.active-dot-on {
  background-color: rgba(0, 255, 0, 0.485);
}

.active-dot-off {
  background-color: rgba(255, 29, 29, 0.643);
}

.line-chart-container-weather-page {
  width: 90%;
  height: 300px;
  background-color: rgb(32, 75, 118);
  margin: 0 auto;
  border-radius: 10px;
}

.weather-table-label {
  margin: 0;
  padding: 10px;
  color: white;
  font-weight: 300;
  margin-left: 20px;
}

.weather-station-tile-container {
  width: 90%;
  max-height: 30%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Adjusted */
  justify-content: center; /* Center grid items horizontally */
  text-align: center;
  align-items: center;
  color: white;
  margin: 0 auto;
}

.weather-station-tile {
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 275px;
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  margin: 45px;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
}

.weather-table-wind-wave-container {
  width: 100%;
  overflow: auto;
  max-height: 100vh;
  background-color: rgba(12,12,20,1);
}

.weather-data-button-container {
  display: flex;
  width: 100%;
  height: 120px;
  justify-content: center;
  z-index: 1;
  background: rgb(12,12,20);
}

.weather-data-button {
  height: 75px;
  width: 75px;
  margin-left: 5%;
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.061);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.12);
}

.active-button {
  background-color: rgba(255, 255, 255, 0.12);
  border: 1px solid rgb(0, 211, 0);
}

.weather-data-button:hover {
  cursor: pointer;
  border: 1px solid orange;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.weather-data-button h3 {
  width: 70%;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  margin: 0 auto;
  text-align: center;
  margin-top: 25%;
  color: white;
}

table {
  width: 100%;
  margin: 10px auto;
  /* border-collapse: collapse; */
  border-spacing: 0px 3px;
  /* background: rgb(20,20,30);
  background: linear-gradient(90deg, rgba(16,15,27,1) 20%, rgba(20,20,30,1) 60%, rgba(16,15,27,1) 100%); */
}

.weather-div-container {
  margin: 20px;
  overflow: auto;
}

.weather-table {
  color: white;
  text-align: center;
}

.weather-table th {
  min-width: 80px;
  padding: 10px;
}

.weather-table tr:first-child th {
  background-color: transparent!important;
}

.weather-table td {
  padding: 0px;
  height: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.weather-table-wind-svg {
  margin: 0px;
  padding: 0px;
  margin-top: -12px;
  margin-bottom: -22px;
}

/* .weather-table tr {
  background: rgb(20,20,30);
  background: linear-gradient(90deg, rgba(20,20,30,1) 30%, rgba(20,20,40,1) 59%, rgba(20,20,30,1) 98%);
} */

.weather-table tr:hover {
  background: rgb(29, 29, 49);
  cursor: pointer;
}

/* wind wave loader */

.electric-wave {
  width: 30%;
  height: 150px;
}

.wave-loader-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  bottom: 50px;
  color: white;
}

.wave-loader-container h3 {
  font-weight: 200;
}

/* Weather table for specific location */

.wind-table-container {
  background-color: rgb(11, 12, 20);
  width: 100%;
  overflow: auto;
  max-height: 100vh;
}

.wind-table-container h2 {
  color: white;
  text-align: center;
  margin-top: 10px;
  padding: 10px;
  font-weight: 400;
}

.wind-table-container div {
  margin: 2%;
}

.line-chart {
}

.wind-table-wind-line-chart {
  min-height: 250px;
}

.wind-wave-line-chart{
  min-height: 300px;
}

/* Fishing Area Map */

.fishing-area-map {
  display: flex;
  width: 100%;
}

.area-search-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(12,12,20,.85);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 5px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  overflow: auto;
}

.fishing-area-label {
  background-color: rgba(83, 83, 120, 1);
  color: white!important;
  padding: 2px 3px;
  border-radius: 5px;
  font-size: 12px!important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border: .5px solid rgba(219, 219, 219, 0.815);
}

.area-search-tile {
  padding: 7px;
  border-bottom: 1px solid #66666665;
  color: white;
  text-align: center;
}

.area-search-tile:hover {
  cursor: pointer;
  background-color: rgba(12,12,20,.7);
}

/* Weather Forecast Page */

.weather-forecast-page {
  display: flex;
  max-height: 100vh;
  overflow: auto;
  background: rgb(12,12,20);
  color: white;
}

.side-panel-container {
  position: relative;
  background: rgb(12,12,20);
  color: white;
  width: 100%;
  overflow: auto;
  margin-bottom: 30px;
}

.side-panel-container h3 {
  margin: 0px 0px 20px 0px;
  padding: 0;
}

.side-panel-container a {
  color: white;
  background-color: blue;
  padding: 5px;
  border-radius: 5px;
}

.side-panel-inline {
  display: inline-flex;
}

.side-panel-inline h5 {
  margin: 5px;
}

.weather-forecast {
  position: relative;
  display: flex;
  /* background-color: rgb(40, 40, 40); */
  background: rgb(20,20,30);
  background: linear-gradient(90deg, rgba(16,15,27,1) 20%, rgba(20,20,30,1) 60%, rgba(16,15,27,1) 100%);
  padding: 40px;
  margin: 30px auto;
  border-radius: 10px;
  min-height: 40px;
  /* max-width: 800px; */
  width: 75%;
}

.weather-forecast p {
  margin-top: 25px;
}

.weather-forecast h5 {
  margin-top: 10px;
  padding: 0;
}

.weather-forecast-info {
  position: absolute;
  top: 2px;
  right: 2%;
  display: flex;
  color: rgba(255, 255, 255, 0.484);
}

.forecast-wind-wave-window {
  margin: 2px;
  display: flex;
  flex-direction: column;
}

.forecast-container {
  display: inline-flex;
  margin: 0;
  padding: 0;
}

.forecast-container h1 {
  margin: 5px;
  padding: 0;
  font-size: 26px;
  font-weight: 400;
}

.forecast-wind-container {
  border-bottom: .5px solid rgba(255, 255, 255, 0.554);
}
  
.weather-forecast-wind-svg {
  margin: 1px;
}

.weather-forecast-container {
  padding: 5px;
}

/* about and contact us */

.about-page, .contact-page {
  background: linear-gradient(rgba(16,15,27, .96), rgba(35, 34, 38, 0.96)), url('/src/images/topography.jpg'); /* Background image */
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  padding: 40px 20px;
  line-height: 1.6;
  color: white;
}

.about-page h1, .contact-page h1 {
  font-size: 2.2rem;
  margin-bottom: 20px;
}

.contact-page a {
  color: white;
}

/* sidebar pro */

.sidebar-pro {
  position: fixed;
  border: none!important;
  box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.082);
  z-index: 9999999999;
}

.sidebar-menu-container {
  position: relative;
  top: 75px;
  font-size: 14px;
  left: 20px;
}

.sidebar-menu-item {
  background-color: rgb(20, 20, 30);
}

.sidebar-menu-item-active {
  color: orange;
}

/* .sidebar-menu-item:hover {
  cursor: pointer;
  color: orange;
} */

/* override components CSS */
.ps-menu-button {
  position: relative;
  left: -15px;
  margin: 0!important;
}

.ps-menu-button a {
  position: relative;
  color: white;
  padding: 0px;
}

.ps-menu-button:hover {
  background-color: rgb(120, 20, 30);
}

.ps-submenu-expand-icon {
  display: none;
}

.ps-active {
  background-color: rgb(20, 20, 30);
}

.sidebar-logo {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 230px;
}

.sidebar-rain {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 150px;
  opacity: 0.55;
}

.ps-menu-button:hover {
  background-color: transparent!important;
}

/* bottom nav */

.bottom-nav-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  height: 40px;
  background-color: rgb(233, 233, 233);
  box-shadow: 0 0 6px rgba(48, 48, 48, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99999;
}

.bottom-nav-item {
  margin: 15px;
}

/* bottom side nav */

.side-menu {
  position: absolute;
  bottom: 40px;
  right: 5px;
  height: 275px;
  width: 50px;
  background-color: rgb(233, 233, 233);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0px -5px 6px rgba(48, 48, 48, 0.2); /* Shadow for top side only */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

/* TOOL TIP */

.sidebar-tool-tip {
  position: absolute;
  z-index: 999999999;
}

/* HELP PAGE */

.help-page {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: rgb(12,12,20);
  cursor: pointer;
  overflow: auto;
}

.help-page-container {
  position: relative;
  background: rgb(12,12,20);
  color: white;
  width: 100%;
  overflow: auto;
}

.video-container {
  width: 100%;
}


.Collapsible {
  margin: 10px;
}

.collapsible-container {
  width: 80vw;
  margin: 20px auto;
}

.Collapsible__contentInner {
  padding: 10px;
  border-top: 0;
  border-radius: 10px;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border-radius: 10px;
  padding: 10px;
  background: rgb(20,20,30);
  background: linear-gradient(90deg, rgba(16,15,27,1) 20%, rgba(20,20,30,1) 60%, rgba(16,15,27,1) 100%);
  color: white;


  &:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}

.title-icon {
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.Collapsible {
  width: 80vw;
  margin: 20px auto;
}

/* FOOTER */

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: rgba(20, 20, 30, 1);
  z-index: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 10px;
}

.footer a {
  color: white;
}

/* homepage footer */

.homepage-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(16, 15, 22);
  color: white;
  padding: 20px;
  font-size: 14px;
}

.footer-right .footer-columns ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-right .footer-columns ul li {
  margin-bottom: 8px;
}

.footer-right .footer-columns ul li a {
  color: white;
}

.footer-right .footer-columns ul li a:hover {
  text-decoration: underline;
}

.footer-center {
  text-align: center;
  flex: 1;
}

.footer-right .footer-columns {
  display: flex;
  gap: 30px;
}

/* Banner ads mobile */

.banner-close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  color: black;
  background-color: white;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center; /* optional, if you also want horizontal centering */
}

.banner-ads-mobile {
  border-radius: 5px;
}

.top-banner-ad-mobile {
  border-radius: 5px;
  width: 80%;
  height: 60px; /* Adjust height as needed */
  overflow: hidden; /* Hide any overflow if image is larger */
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 55px;
  left: 5px;
  z-index: 9; /* Ensure it's above other content */
}

.side-banner-ad-mobile {
  border-radius: 5px;
  width: 55px;
  height: 290px; /* Adjust height as needed */
  overflow: hidden; /* Hide any overflow if image is larger */
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 305px;
  right: 5px;
  z-index: 9; /* Ensure it's above other content */
}

/* Top Banner */

.top-banner-ad {
  width: 65%;
  height: 60px; /* Adjust height as needed */
  overflow: hidden; /* Hide any overflow if image is larger */
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 40px;
  left: 185px;
  z-index: 9; /* Ensure it's above other content */
}

/* Content Wrapper with Side Banner */
.content-with-side-banner {
  display: flex;
  margin-top: 0px; /* Offset for top banner */
  margin-right: 0px;
}

/* Main Content */
.main-content {
  flex: 1;
}

/* Side Banner */
.side-banner-ad {
  width: 150px; /* Adjust width as needed */
  height: 600px; /* Adjust height as needed */
  background-color: #f1f1f1;
  position: sticky;
  top: 0px; /* Adjust to control how far down it scrolls */
  right: 10px;
}

/* Bottom Banner */
.bottom-banner-ad {
  width: 80%;
  height: 90px; /* Adjust height as needed */
  background-color: #f1f1f1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 75px;
  left: 185px;
  z-index: 1000;
  padding: 10px;
}

/* Sample Ad Image Styling */
.top-banner-ad img,
.side-banner-ad img,
.bottom-banner-ad img {
  width: 100%; /* Stretch to the full width of the container */
  height: 100%; /* Stretch to the full height of the container */
}

/* sub area map status card */

.sub-area-id {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(29, 29, 29, 0.8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 9999;
}

.fishing-reg-partial-opening {
  width: 300px;
  margin: 50px;
}

.fishing-reg-partial-opening a {
  color: white;
}

.partial-opening-icon{
  position: absolute;
  right: 17%;
  z-index: 9999;
}

/* new features banner */

.new-features-banner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  max-width: 500px;
  height: 50%;
  background-color: rgb(235, 235, 235);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 99999;
  padding: 15px;
  border-radius: 10px;
  padding: 20px;
}

.new-features-banner h2 {
  margin: 0;
  padding: 0;
  text-align: center;
}

.new-features-banner p {
  font-size: .95rem;
  font-weight: 400;
  margin-top: 10px;
}

.new-feature-img {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 35%;
  min-width: 140px;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.6);
}

.new-feature-photo-img {
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 70%;
  min-width: 250px;
  height: 45%;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.6);
}

.arrow {
  position: absolute;
  bottom: 80px;
  left: 67%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid rgb(255, 102, 0);
  margin: 10px auto;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* feedback page */

.feedback-container {
  background-color: rgb(12,12,20);
  color: white;
}

.feedback-page {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
}

.feedback-container img {
  position: absolute;
  top: 20px;
  left: 50px;
  width: 300px;
}

.feedback-container h1 {
  font-weight: 300;
  max-width: 500px;
  margin: 50px;
  text-align: center;
}

.feedback-container button {
  position: relative;
  left: 100px;
  margin-top: -100px;
}

.feedback-container button:hover {
  background-color: rgb(2, 61, 209);
}

.feedback-input-container textarea {
  margin: 50px;
  width: 400px;
  height: 200px;
  border-radius: 5px;
  font-size: 18px;
  padding: 5px;
}

textarea {
  resize: none;
}

@media only screen and (max-width: 600px) {

  .feedback-page {
    flex-direction: column; /* Stack content vertically */
    text-align: center; /* Center all content horizontally */
  }

  .feedback-container img {
    position: absolute; /* Remove absolute positioning */
    width: 250px; /* Adjust image width */
    top: 0;
    left: 0;
  }

  .feedback-container h1 {
    position: relative;
    top: 50px;
    font-size: 24px; /* Decrease font size */
  }

  .feedback-input-container textarea {
    position: relative;
    width: 90%; /* Take full width */
    max-width: 90%; /* Ensure it doesn't exceed container width */
    height: 150px; /* Decrease height */
    margin: 10px 0; /* Adjust top and bottom margins */
    padding: 5px; /* Adjust padding */
    font-size: 16px;
    top: -100px;
  }

  .feedback-container button {
    left: 0px; /* Reset left positioning */
    bottom: 80px;
  }
}

/* weather map mapbox */

.weather-arrow-icon {
  position: absolute;
  top: 70%;
  left: 120%;
  transform: translate(-50%, -50%);
  z-index: 999;
  cursor: pointer;
}

.chart-scroll-container {
  width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents line breaks */
}

.chart-wrapper {
  min-width: 800px; /* Adjust this value based on your dataset */
}

.wind-speed-label {
  font-weight: 500;
  width: fit-content;
  min-width: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.204);
  background-color: white;
  border-radius: 20px;
  padding: 0px 2px;
  font-size: 14px;
  text-align: center;
}

.wind-speed-label-small-text {
  font-weight: 500;
  width: fit-content;
  min-width: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.204);
  background-color: white;
  border-radius: 20px;
  padding: 0px 2px;
  font-size: 11px;
  text-align: center;
}

.wind-speed-label-hovered {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.563);
  font-weight: 450;
  cursor: pointer;
}

.wind-speed-label-small-text:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.563);
  font-weight: 450;
  cursor: pointer;
}

.wave-height-label {
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  width: fit-content;
  min-width: 22px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.204);
  background-color: rgb(78, 78, 78);
  border-radius: 10px;
  padding: 0px 3px;
  color: white;
}

.wave-height-label-hovered {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.563);
  font-weight: 450;
  cursor: pointer;
}

.weather-popup p {
  margin: 0;
  padding: 0;
}

.line-chart-container {
  margin-top: 10px;
}

.wind-speed-bar {
  z-index: 99;
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
}

.wind-speed-labels {
  z-index: 9999;
  position: absolute;
  width: 86%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 12px;
  color: white;
  font-weight: 600;
}

.wind-speed-gradient {
  width: 100%;
  height: 20px; /* Adjust the height as needed */
  background: hsla(264, 86%, 47%, 1);
  background: linear-gradient(90deg, hsla(264, 86%, 47%, 1) 9%, hsla(222, 100%, 50%, 1) 20%, hsla(147, 94%, 47%, 1) 38%, hsla(60, 100%, 43%, 1) 61%, hsla(34, 100%, 50%, 1) 80%, hsla(0, 100%, 46%, 1) 100%,  rgb(117, 0, 0) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#6411E1", endColorstr="#004CFF", GradientType=1 );
  border-radius: 5px;
}

.wave-height-gradient {
  width: 100%;
  height: 20px; /* Adjust the height as needed */
  background: hsla(264, 86%, 47%, 1);
  background: linear-gradient(90deg, hsla(264, 86%, 47%, 1) 15%, hsla(222, 100%, 50%, 1) 23%, hsla(147, 94%, 47%, 1) 40%, hsla(60, 100%, 43%, 1) 61%, hsla(34, 100%, 50%, 1) 80%, hsla(0, 100%, 46%, 1) 100%,  rgb(117, 0, 0) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#6411E1", endColorstr="#004CFF", GradientType=1 );
  border-radius: 5px;
}

.wind-wave-forecast-table-container {
  position: relative;
  max-width: 100%;
  max-height: 300px; /* ✅ Set max height */
  overflow-x: auto;  /* ✅ Enable horizontal scrolling */
  overflow-y: auto;  /* ✅ Enable vertical scrolling */
  background: #111;
  padding: 10px;
  border-radius: 8px;
}

.wind-wave-forecast-table-container table {
  width: 100%;
  min-width: 600px; /* ✅ Ensures table doesn't shrink too much */
  border-collapse: collapse;
  text-align: center;
  color: white;
}

.wind-wave-forecast-table-container th,
.wind-wave-forecast-table-container td {
  border: .5px solid rgba(255, 255, 255, 0.1);
  padding: 8px;
  white-space: nowrap; /* ✅ Prevent text wrapping */
}

.wind-wave-forecast-table-container th {
  background-color: rgba(12, 12, 20, .94);
  color: white;
  position: sticky;
  top: -11px; /* ✅ Keeps headers visible when scrolling */
}

.wind-wave-forecast-table-container tbody tr:nth-child(even) {
  background: rgba(255, 255, 255, 0.05);
}

.highlighted-row {
  background-color: rgba(255, 255, 255, 0.221)!important; /* Light highlight */
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;
}

.set-forecast-radius-button {
  position: absolute;
  z-index: 999999;
  top: 200px;
  right: 200px;
  background-color: rgb(246, 11, 11);
}

.forecast-radius-button {
  position: absolute;
  bottom: 75px;
  right: 50px;
  padding: 12px 16px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  border: none;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;
  /* Throbbing Effect */
  animation: throbbing 1.5s infinite ease-in-out;
}

.loading-spinner-forecast-selector {
  position: absolute;
  bottom: 85px;
  right: 70px;
  width: 36px; /* Set a specific width for the spinner */
  height: 36px;
}

/* Keyframes for Throbbing */
@keyframes throbbing {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.15); /* Slightly larger */
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* .wind-wave-forecast-table-container tbody tr:hover {
  background: rgba(255, 165, 0, 0.3);
}
 */

.popup-toggle {
  position: absolute;
  top: 15px;
  right: 78px;
}

.popup {
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(12, 12, 20, .6);
  border-radius: 25px;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  gap: 10px;
  align-items: center;
}

.option {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  background-color: rgba(12, 12, 20, 0);
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: white;
}

.option:hover {
  background-color: #e0e0e063;
}

.option.selected {
  background-color: #ffffffd0;
  color: black;
}

@keyframes pulseText {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.3); } /* Adjust size for effect */
}

.pulse-animation {
  animation: pulseText 1s ease-in-out 2; /* Pulses 3 times */
}

/* Weekly fishing report */

.article-narrow-container {
  position: relative;
  display: flex;
  width: 100%;
}

.article-narrow-container img {
  width: 500px;
  max-width: 90%;
  max-height: 500px;
  margin: 20px;
}

.article-container { 
  padding: 25px;
  max-width: 700px;
  background: rgb(12,12,20);
  height: 100vh;
  overflow: auto;
  color: white;
  justify-content: center;
  font-size: 20px;
}

.article-narrow-container p {
  margin: 0;
  padding: 10px;
}
.article-narrow-container h2 {
  font-style: italic;
  margin: 0;
  padding: 10px;
}
.article-narrow-container h3 {
  border-top: .5px solid rgba(255, 255, 255, 0.396);
  margin: 0;
  padding: 20px;
  text-align: center;
  font-size: 26px;
}
.article-narrow-container h4 {
  margin: 0;
  padding: 10px;
}

/* Destination map */

.popup-icon {
  position: absolute;
  top: 20px;
  right: 25px;
}

.gov-anchorage-popup {
  /* display in a line */
  display: flex;
  justify-content: space-between;
}

.destination-map-icon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  border: 1px solid white;
}

.marker-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  border: 1px solid white;
}

/* terms and conditions */

/* Add this new container to hold sidebar and terms */
.terms-container {
  display: flex;
  color: white;
}

/* Keep the existing styles but tweak them */
.terms-and-conditions {
  width: 70%; /* Adjust width so it takes space beside the sidebar */
  padding: 20px; /* Add some padding to give breathing room */
  /* scroll */
  overflow-y: auto;
  height: 100vh;
}

/* For mobile views, you can stack them vertically */
@media (max-width: 600px) {
  .terms-container {
    flex-direction: column;
  }

  .terms-and-conditions {
    width: 100%;
  }
}

/* sidebar search */

/* Sidebar styles */
.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 300px;
  background: linear-gradient(90deg, rgba(16, 15, 27, 0.926) 20%, rgba(20, 20, 30, 0.958) 60%, rgba(16, 15, 27, 0.956) 100%);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(100%); /* Hidden offscreen initially */
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.sidebar-small {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 175px;
  background: linear-gradient(90deg, rgba(16, 15, 27, 0.926) 20%, rgba(20, 20, 30, 0.958) 60%, rgba(16, 15, 27, 0.956) 100%);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(100%); /* Hidden offscreen initially */
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

/* Sidebar when open */
.sidebar.open, .sidebar-small.open {
  transform: translateX(0); /* Slides into view */
}

/* Sidebar content */
.sidebar .content, .sidebar-small .content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Headings */
.sidebar h2, .sidebar h3, .sidebar-small h2, .sidebar-small h3 {
  color: #f0f0f0;
  margin-bottom: 15px;
  font-size: 1.5rem;
  border-bottom: 1px solid #333; /* Underline for titles */
  padding-bottom: 5px;
}

/* Search input field */
.sidebar input[type="text"], .sidebar-small input[type="text"] {
  width: 60%;
  padding: 10px;
  margin: 10px 0;
  background-color: #333; /* Dark input background */
  color: #fff; /* White text */
  border: 1px solid #444; /* Subtle border */
  border-radius: 5px;
  outline: none;
}

.sidebar input[type="text"]:focus, .sidebar-small input[type="text"]:focus {
  border-color: #007bff; /* Blue focus border */
}

/* Checkbox styles */
.sidebar label, .sidebar-small label {
  display: block;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #e0e0e0;
  cursor: pointer;
}

.sidebar input[type="checkbox"], .sidebar-small input[type="checkbox"],
.sidebar input[type="radio"], .sidebar-small input[type="radio"] {
  margin-right: 10px;
  accent-color: #007bff; /* Blue checkboxes */
  cursor: pointer;
}

/* Search button */
.sidebar button, .sidebar-small button {
  position: absolute;
  bottom: 145px;
  background: linear-gradient(135deg, #e99903, #7c6013); /* Gradient background */
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 90%;
  transition: background-color 0.3s ease;
}

/* Toggle button styles */
.toggle-button {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: orange;
  color: white;
  width: 30px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000; /* Above the sidebar */
  border-radius: 5px 0 0 5px;
}

/* Toggle button icon */
.toggle-button span {
  font-size: 20px;
  line-height: 1;
  transform: rotate(180deg);
}

.sidebar-clear-btn {
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.legend-bar {
  display: flex;
  flex-wrap: wrap; /* Allow elements to wrap */
  justify-content: space-around; /* Evenly spread the items */
  align-items: center; /* Vertically center the items */
  position: absolute; /* Fix it to the top */
  top: 5px; /* Stick to the top */
  left: 50%; /* Move the left edge of the element to the middle of the page */
  transform: translateX(-50%); /* Offset the element by half its width to center it */
  z-index: 1; /* Ensure it stays on top of the map */
  overflow-x: auto; /* Ensure items can scroll horizontally if needed */
  width: 70%; /* Ensure it takes full width */
  max-width: 800px;
  padding-bottom: 5px;
  box-sizing: border-box; /* Include padding in width calculations */
}

.legend-item {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 3px 5px;
  border-radius: 20px;
  margin-left: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.355);
}

.color-box {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 50%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.355);
}

/* Mobile media CSS */

@media only screen and (max-width: 600px) {

  .ps-submenu-content {
    position: relative;
    left: 15px!important;
  }

  .legend-item {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 3px 5px;
    border-radius: 20px;
    margin-left: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.355);
    font-size: 9px;
  }

  .color-box {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 5px;
    border-radius: 50%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.355);
  }

  .wind-speed-bar {
    z-index: 99;
    position: fixed;
    bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
  }

  .slider-container {
    position: fixed;
    bottom: 35px;
    left: 5px;
    width: 95%;
    min-height: 30px;
    background-color: rgba(12, 12, 20, .6);
    border-radius: 10px;
    padding: 6px;
    display: flex;
    align-items: center;
  }

  .wind-speed-gradient {
    position: relative;
    width: 100%;
    left: 0px;
    height: 20px; /* Adjust the height as needed */
    background: hsla(264, 86%, 47%, 1);
    background: linear-gradient(90deg, hsla(264, 86%, 47%, 1) 9%, hsla(222, 100%, 50%, 1) 20%, hsla(147, 94%, 47%, 1) 38%, hsla(60, 100%, 43%, 1) 61%, hsla(34, 100%, 50%, 1) 80%, hsla(0, 100%, 46%, 1) 100%,  rgb(117, 0, 0) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#6411E1", endColorstr="#004CFF", GradientType=1 );
    border-radius: 5px;
  }

  .popup-toggle {
    position: absolute;
    top: 15px;
    right: 78px;
  }

  .forecast-hour {
    position: fixed;
    left: 5px;
    bottom: 82px;
    width: 65px;
    height: 25px;
    background-color: rgba(12, 12, 20, .6);
    border-radius: 10px;
    z-index: 99;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .wind-speed-window {
    position: absolute;
    left: 50px;
    width: 90px;
    max-height: 10px;
    bottom: 20px;
    padding: 22px 5px;
    display: flex;
    justify-content: center;
  }

  .dropdown-msg-container {
    left: 10px;
  }

  .mobile-wrap{
    width: 140px;
    font-size: 16px;
  }

  .weather-forecast-info {
    position: absolute;
    transform: scale(0.8);
    right: -25px;
  }

  .modal-wrapper-large {
    /* hides the border radius*/
    min-height: 103%;
    z-index: 999;
    background-color: rgba(12, 12, 20, 1);
    width: 100%;
  }

  .fishing-course-modal-content {
    width: 100vw;
    height: 100vh;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    font-family: 'Arial', sans-serif;
    text-align: left;
    margin-left: 0px;
    z-index: 999999999;
  }

  .fishing-regs-tile-container {
    width: 100%;
    max-height: 525px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    position: relative;
    row-gap: 0px;
    overflow: scroll; /* or auto, depending on your preference */
  }

  .terms-and-conditions {
    width: 80%;
    min-height: 100vh;
    color: white;
    margin: 0 auto;
  }

  .sidebar-clear-btn {
    position: absolute;
    bottom: 120px;
    right: 15px;
    color: white;
    cursor: pointer;
    font-size: 14px;
  }

  .legend-bar {
    display: flex;
    flex-wrap: wrap; /* Allow elements to wrap */
    justify-content: space-evenly; /* Evenly spread the items */
    align-items: center; /* Vertically center the items */
    position: absolute; /* Fix it to the top */
    top: 0; /* Stick to the top */
    left: 50%; /* Move the left edge of the element to the middle of the page */
    transform: translateX(-50%); /* Offset the element by half its width to center it */
    padding: 10px;
    z-index: 1; /* Ensure it stays on top of the map */
    overflow-x: auto; /* Ensure items can scroll horizontally if needed */
    width: 95%; /* Ensure it takes full width */
    box-sizing: border-box; /* Include padding in width calculations */
  }

  .modal-wrapper-wide {
    position: fixed; /* Stay in place */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99; /* Sit on top */
    /* overflow: auto; Enable scroll if needed */
    background-color: rgba(12, 12, 20, .94);
    width: 100%;
    height: 75vh;
  }

  .loading-spinner-forecast-selector {
    position: absolute;
    bottom: 183px;
    right: 20px;
    width: 36px; /* Set a specific width for the spinner */
    height: 36px;
  }

  .forecast-radius-button {
    position: absolute;
    bottom: 170px;
    right: 5px;
    padding: 12px 16px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    border: none;
    font-weight: bold;
    transition: background-color 0.3s ease-in-out;
    /* Throbbing Effect */
    animation: throbbing 1.5s infinite ease-in-out;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 98; /* Below modal but above page */
  }

  .sidebar-menu-container {
    position: relative;
    top: 75px;
    font-size: 14px;
    left: 0px;
  }

  .sidebar-container {
    width: 100%;
    height: 100vh; /* Takes up 40% of screen height */
    top: 0;
    left: 0;
    top: auto;
    border-left: none;
    border-top: 2px solid #444;
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
  }

  /* Minimized state */
  .sidebar-container.minimized {
    height: 100px; /* Shrinks the panel */
    overflow: hidden;
    width: 100vw;
    backdrop-filter: blur(10px);
  }

  .location-table th, .location-table td {
    padding: 6px;
    font-size: 12px;
  }

  .toggle-sidebar {
    top: 10px;
    left: 10px;
    padding: 8px;
  }

  .homepage-footer {
      flex-direction: column;
      text-align: center;
  }

  .footer-left,
  .footer-right {
      margin-top: 10px;
  }

  .footer-right .footer-columns {
      flex-direction: column;
      align-items: center;
  }

  .home-page-container-reverse {
    flex-direction: column-reverse; /* Text first, image on bottom */
    text-align: center;
  }

  .fishing-map-species-search {
    position: absolute;
    top: 100px;
    left: 0px;
    color: white;
    margin: 0;
    padding: 0;
  }

}